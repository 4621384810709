import React from 'react';
import styles from './OperationsPrint.module.css';
import spotViewLogo from '../../assets/logoSpotView.png';
import spotsatLogo from '../../assets/spotsatLogo.png';
import Icon from '../../components/Icon/Icon.component';
import { Cell, Legend, Pie, PieChart } from 'recharts';
import barraPlantio from '../../assets/barraPlantio.png';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import OperationMS from '../../services/ms/OperationMS.service';
import { OperationMSTypes } from '../../types/definitions';
import toLocaleDate from '../../utils/toLocaleDate.util';
import toCurrency from '../../utils/toCurrency.util';
import { Chart } from '../../components/Charts';
import CustomMap from '../../components/Map/Map.component';
import GlebeOverlay from '../../components/Map/overlays/GlebeOverlay.overlay';
import getBbox from '../../utils/geo/getBbox.util';
import getCentroid from '../../utils/geo/getCentroid.util';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import toFeatureCollection from '../../utils/geo/toFeatureCollection.util';
import groupBy from '../../utils/groupBy.util';
import ComplianceOverlay from '../../components/Map/overlays/ComplianceOverlay.overlay';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  data,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      fontSize={12}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

function OperationsPrint(): JSX.Element {
  const { id } = useParams();
  const navigate = useNavigate();

  const [dataWeatherGlobal, setDataWeatherGlobal] = useState<any>({
    snow: [],
    snowData: [],
    thunderStorm: [],
    thunderStormData: [],
    hail: [],
    hailData: [],
  });

  const [data, setData] = useState<OperationMSTypes.ById | undefined>(
    undefined
  );
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    async function fetchData() {
      if (!id) return;

      const responses = await Promise.allSettled([OperationMS.ById(id)]);

      responses.forEach((response, index) => {
        if (response.status !== 'fulfilled') {
          return;
        }
        if (response.value === false) {
          return;
        }

        if (index === 0) {
          setData(response.value as OperationMSTypes.ById);
        }
      });
    }

    fetchData();
  }, [id]);

  useEffect(() => {
    if (!data) return;

    setTimeout(() => {
      setIsReady(true);
    }, 5000);
  }, [data]);

  if (!id) {
    navigate(-1);
    return <></>;
  }

  if (!data) {
    return <p>carregando...</p>;
  }

  const weather = data.glebes.find((glebe) => !!glebe.weather)?.weather;

  const geometry: GeoJSON.FeatureCollection = toFeatureCollection(
    data.glebes.map((glebe) => glebe.geom)
  );
  const sum_area = data.glebes.reduce(
    (accumulator, currentValue) =>
      accumulator + Number(currentValue.area.replace(',', '.')),
    0
  );
  const summaries = groupBy(
    data.glebes.flatMap((glebe) => glebe.compliance.summary),
    'name'
  );

  const formatedSummaries = Object.keys(summaries).reduce(
    (accumulator: any, currentValue: any) => {
      const currentObj = summaries[currentValue];

      const sum_ha: number = currentObj.reduce(
        (accumulator, currentValue) =>
          accumulator + Number(currentValue.intersection_area_ha),
        0
      );

      const newCurrent = {
        name: currentValue,
        intersection_area_ha: sum_ha,
        intersection_area_percentage: (sum_ha / sum_area) * 100,
      };

      return [...accumulator, newCurrent];
    },
    []
  );

  const total_informed_area = Number(data.area.replace(',', '.'));
  const total_agricultable_area = data.glebes.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue.geom_planting.properties.area_ha,
    0
  );
  const total_non_agricultable_area =
    total_informed_area - total_agricultable_area;

  const dataGlebe = [
    {
      name: 'Área total informada',
      value: total_informed_area,
      fill: '#009900',
    },
    {
      name: 'Área não agricutável',
      value: total_non_agricultable_area,
      fill: '#FF3333',
    },
  ];

  const columns = [
    { field: 'name', headerName: 'Camada', flex: 1, minWidth: 300 },
    {
      field: 'intersection_area_ha',
      headerName: 'Área (ha)',
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) => `${params.value.toFixed(2)} ha`,
    },
    {
      field: 'intersection_area_percentage',
      headerName: 'Área (%)',
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) => `${params.value.toFixed(2)}%`,
    },
  ] satisfies GridColDef[];

  return (
    <div className={styles.file}>
      {isReady && <span className="ready-to-download"></span>}
      <div className={styles.page}>
        <header>
          <h1>LAUDO AGRÍCOLA</h1>
          <h2>Relatório</h2>
          <div className={styles.headerIcons}>
            <span>
              <img src={spotViewLogo} alt="Logo SpotView" width={100} />
            </span>
            <span>
              <img src={spotsatLogo} alt="Logo Spotsat" width={100} />
            </span>
          </div>
        </header>
        <hr />
        <section>
          <div className={styles.headerDates}>
            <p>Criado em {toLocaleDate(data.created, true)}</p>
            <p>Atualizado {toLocaleDate(data.modified, true)}</p>
            <p>Emitido em {toLocaleDate(new Date().toISOString(), true)}</p>
          </div>
          <div className={styles.infosContainer}>
            <div className={styles.infoItem}>
              <h3>Nome</h3>
              <p>{data.owner}</p>
            </div>
            <div className={styles.infoItem}>
              <h3>CPF</h3>
              <p>{data.owner_document}</p>
            </div>
            <div className={styles.infoItem}>
              <h3>CNPJ Cooperativa</h3>
              <p>{data.company.document_number}</p>
            </div>
            <div className={styles.infoItem}>
              <h3>Financiador</h3>
              <p>{data.company.name}</p>
            </div>
            <div className={styles.infoItem}>
              <h3>Ref. BACEN</h3>
              <p>{data.bacen_code}</p>
            </div>
            <div className={styles.infoItem}>
              <h3>Número da operação</h3>
              <p>{data.code}</p>
            </div>
            <div className={styles.infoItem}>
              <h3>Área financiada</h3>
              <p>
                {data.financed_area} {data.area_type}
              </p>
            </div>
            <div className={styles.infoItem}>
              <h3>Cultura financiada</h3>
              <p>{data.culture_name}</p>
            </div>
            <div className={styles.infoItem}>
              <h3>Localização</h3>
              <p>
                {data.address.city} - {data.address.state}
              </p>
            </div>
            <div className={styles.infoItem}>
              <h3>Valor financiado</h3>
              <p>{toCurrency(data.total_financed)}</p>
            </div>
            <div className={styles.infoItem}>
              <h3>Latitude e Longitude</h3>
              <p>
                {data.coordinates.lat} / {data.coordinates.lng}
              </p>
            </div>
          </div>
        </section>
        <hr />
        <section>
          <div className={styles.sectionTitle}>
            <Icon name="Alert" />
            <h3>Alertas</h3>
          </div>
          <div className={styles.alertList}>
            <ul>
              {data.alerts.map((alert, index) => (
                <li key={index}>{alert.description}</li>
              ))}
            </ul>
          </div>
        </section>
        <hr />
        <section>
          <div className={styles.sectionTitle}>
            <Icon name="Plant" />
            <h3>Produção</h3>
          </div>
          <div className={styles.productionContent}>
            <div className={styles.productionItem}>
              <h3>Previsão de plantio</h3>
              <p>{toLocaleDate(data.planting_start)}</p>
            </div>
            <div className={styles.productionItem}>
              <h3>Previsão de colheita</h3>
              <p>{toLocaleDate(data.crop_start)}</p>
            </div>
            <div className={styles.productionItem}>
              <h3>Produtividade média da região</h3>
              <p>
                {data.production_average_region}{' '}
                {data.production_average_region_unity} / {data.area_type}
              </p>
            </div>
            <div className={styles.productionItem}>
              <h3>Tipo de solo</h3>
              <p>{data.soil}</p>
            </div>
          </div>
        </section>
        <hr />
        <section>
          <div className={styles.sectionTitle}>
            <Icon name="Book" />
            <h3>Análise para Manual de Crédito Rural (MCR)</h3>
          </div>
          <div className={styles.analyzeRuralCreditContent}>
            <div className={styles.analyzeRuralCreditItem}>
              <h3>Cultura</h3>
              <p>{data.validated_culture || 'Não disponível'}</p>
            </div>
            <div className={styles.analyzeRuralCreditItem}>
              <h3>Precisão</h3>
              <p>{data.precision}%</p>
            </div>
          </div>
        </section>
        <hr />
        <section>
          <div className={styles.sectionTitle}>
            <Icon name="SunReport" />
            <h3>Clima</h3>
          </div>
          <Chart.Weather
            data={weather}
            dataWeatherGlobal={dataWeatherGlobal}
            setDataWeatherGlobal={setDataWeatherGlobal}
          />
          {dataWeatherGlobal.snow.length > 0 ||
          dataWeatherGlobal.thunderStorm.length > 0 ||
          dataWeatherGlobal.hail.length > 0 ? (
            <div className={styles.divAlertWeather}>
              <h3>
                <Icon name="Radar" />
                Alerta de Clima
              </h3>
              <div className={styles.divAlerts}>
                {dataWeatherGlobal.thunderStormData.some(
                  (item) => item.Tempestade !== 0 && item.Tempestade
                ) && (
                  <p className={`${styles.alert} ${styles.alertThunderStorm}`}>
                    Tempestade
                  </p>
                )}
                {dataWeatherGlobal.snowData.some(
                  (item) => item.Geada !== 0 && item.Geada
                ) && (
                  <p className={`${styles.alert} ${styles.alertSnow}`}>Geada</p>
                )}
                {dataWeatherGlobal.hailData.some(
                  (item) => item.Granizo !== 0 && item.Granizo
                ) && (
                  <p className={`${styles.alert} ${styles.alertHail}`}>
                    Granizo
                  </p>
                )}
              </div>
            </div>
          ) : (
            ''
          )}
        </section>
      </div>

      <div className={styles.page}>
        <section className={styles.complianceHeaderSection}>
          <h1>COMPLIANCE AMBIENTAL</h1>
          <h2>Relatório</h2>
          <div className={styles.complianceIcons}>
            <span>
              <img src={spotViewLogo} alt="Logo SpotView" width={100} />
            </span>
            <span>
              <img src={spotsatLogo} alt="Logo SpotSat" width={100} />
            </span>
          </div>
        </section>
        <hr />
        <section className={styles.complianceSection}>
          <div className={styles.map}>
            <CustomMap
              center={getCentroid(geometry)}
              zoom={15}
              mapProps={{
                className: styles.map,
                maxBounds: getBbox(geometry),
              }}
              overlays={[<ComplianceOverlay data={data.glebes} />]}
            />
          </div>
          <DataGrid
            initialState={{ pagination: { paginationModel: { pageSize: 25 } } }}
            pageSizeOptions={[25]}
            columns={columns}
            rows={formatedSummaries}
            getRowId={(row) => row.name}
            sx={{ '& .MuiDataGrid-main': { width: 0, minWidth: '100%' } }}
            disableColumnResize
            disableColumnMenu
            autoHeight
            hideFooter
          />
        </section>
      </div>

      {data.glebes.map((glebe, index) => {
        const lastGlebe = Array.from(glebe.chart_ndvi.data || [])
          .reverse()
          .find((c) => c.y.filter(Boolean).length > 0);

        const coordinates: string = glebe.coordinates_area
          .map((coord) => [`[Lat: ${coord.lat} Lon: ${coord.lng}]`])
          .join(', ');

        const chunks: string[] = [];
        const chunkSize = 2400;

        for (let i = 0; i < coordinates.length; i += chunkSize) {
          chunks.push(coordinates.slice(i, i + chunkSize));
        }

        const chunksWithoutFirst = chunks.slice(1);
        const groupedChunks: string[] = [];
        const n = 3;

        for (let i = 0; i < chunks.length; i += n) {
          const largerChunk = chunksWithoutFirst.slice(i, i + n);
          groupedChunks.push(largerChunk.join(', '));
        }

        return (
          <React.Fragment key={index}>
            <div className={styles.page}>
              <section className={styles.glebeSection}>
                <div className={styles.sectionTitle}>
                  <Icon name="SquareHalfBottom" />
                  <h3>Gleba {index + 1}</h3>
                </div>
                <div className={styles.glebeContent}>
                  <div className={styles.glebeData1}>
                    <div className={styles.glebeItem}>
                      <h3>Área informada</h3>
                      <p>{glebe.area}</p>
                    </div>
                    <div className={styles.glebeItem}>
                      <h3>Área de Plantio</h3>
                      <p>{glebe.planting_area}</p>
                    </div>
                    <div className={styles.glebeItem}>
                      <h3>Estágio Vegetativo Alcançado</h3>
                      <p>
                        {glebe.chart_ndvi.data.length > 3
                          ? lastGlebe?.name.split('(')[0]
                          : 'Não disponível'}
                      </p>
                    </div>
                    <div className={styles.glebeItem}>
                      <h3>Coordenadas Geodésicas</h3>
                      <p>{glebe.geodetic_coordinate}</p>
                    </div>
                  </div>
                </div>
                <div className={styles.map}>
                  <CustomMap
                    center={getCentroid(glebe.geom)}
                    mapProps={{
                      className: styles.map,
                      maxBounds: getBbox(glebe.geom),
                    }}
                    overlays={[<GlebeOverlay data={glebe} />]}
                  />
                </div>
                <p className={styles.glebeCoordinates}>{chunks[0]}</p>
              </section>
            </div>
            {chunks.length > 1 &&
              groupedChunks.map((chunk, index) => {
                if (!chunk) return null;

                return (
                  <div className={styles.page} key={index}>
                    <section className={styles.glebeSection}>
                      <div className={styles.glebeCoordinates}>{chunk}</div>
                    </section>
                  </div>
                );
              })}
            <div className={styles.page}>
              <section className={styles.glebeSection}>
                <div className={styles.glebeImages}>
                  {glebe.images.map((image, index) => (
                    <div className={styles.glebeImage}>
                      <img src={image.url} alt={image.text} width={100} />
                      <p>Pós-plantio</p>
                      <p>15 de Maio de 2023</p>
                    </div>
                  ))}
                  <div>
                    <img src={barraPlantio} alt="" height={170} />
                  </div>
                </div>
              </section>
              <section>
                <div className={styles.sectionTitle}>
                  <Icon name="TrendingUp" />
                  <h3>Gráfico NDVI</h3>
                </div>
                <div className={styles.ndviChart}>
                  <Chart.NDVI
                    glebe={glebe}
                    planting={{
                      start: data.planting_start,
                      end: data.planting_end,
                    }}
                    crop={{ start: data.crop_start, end: data.crop_end }}
                  />
                </div>
              </section>
            </div>
          </React.Fragment>
        );
      })}

      <div className={styles.page}>
        <section>
          <div className={styles.sectionTitle}>
            <Icon name="GitBranch" />
            <h3>Análise socioambiental</h3>
          </div>

          <div className={styles.socioEnvironmentalContent}>
            <div className={styles.socioEnvironmentalData1}>
              <div className={styles.weatherItem}>
                <h3>Área total financiada</h3>
                <p>
                  {data.financed_area} {data.area_type}
                </p>
              </div>
              <div className={styles.weatherItem}>
                <h3>Área total informada</h3>
                <p>
                  {data.area} {data.area_type}
                </p>
              </div>
              <div className={styles.weatherItem}>
                <h3>Área total de plantio</h3>
                <p>
                  {data.planting_area_total} {data.area_type}
                </p>
              </div>
            </div>
            <div className={styles.pieChart}>
              <PieChart
                width={200}
                height={145}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Pie
                  data={dataGlebe}
                  dataKey="value"
                  nameKey="name"
                  cx="30%"
                  cy="50%"
                  outerRadius={70}
                  isAnimationActive={false}
                  label={renderCustomizedLabel}
                  labelLine={false}
                />
                {dataGlebe.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.fill} />
                ))}
                <Legend
                  align="right"
                  verticalAlign="middle"
                  layout="vertical"
                />
              </PieChart>
            </div>
          </div>
        </section>
        <hr />
        <section className={styles.fieldSection}>
          <div className={styles.sectionTitle}>
            <Icon name="MapReport" />
            <h3>Talhão</h3>
          </div>
          <div className={styles.fieldContent}>
            <div className={styles.fieldItem}>
              <h3>Número</h3>
              <p>{data.code}</p>
            </div>
            <div className={styles.fieldItem}>
              <h3>Área</h3>
              <p>
                {data.area} {data.area_type}
              </p>
            </div>
            <div className={styles.fieldItem}>
              <h3>Zoneamento</h3>
              <p>{data.zoning}</p>
            </div>
            <div className={styles.fieldItem}>
              <h3>Plantio</h3>
              <p>
                {toLocaleDate(data.planting_start)} à{' '}
                {toLocaleDate(data.planting_end)}
              </p>
            </div>
            <div className={styles.fieldItem}>
              <h3>Colheita</h3>
              <p>
                {toLocaleDate(data.crop_start)} à {toLocaleDate(data.crop_end)}
              </p>
            </div>
          </div>
        </section>
        <hr />
        <section className={styles.footerSection}>
          <div>
            <h3> Responsabilidade da análise</h3>
            <p>
              Responsável pela análise: SpotSat LTDA. Interpretação e Elaboração
              de Conclusões: SpotEye. Responsável pelo Sensoriamento Remoto:
              SpotSat LTDA. Instituição Financeira e Responsável:{' '}
              {data.company.name}
            </p>
          </div>
          <div>
            <h3>Dados técnicos</h3>
            <p>
              Satélite Sentinel 2 | Sensor MSI | Coleta de 01/06/2022 à
              30/06/2022 | Resolução espacial de 10m | Resolução radiométrica de
              12bits/pixel | Bandas utilizadas: B08, B04 | Pré-processamento com
              método correção TOA | Processamento das imagens: NDVI e EVI
            </p>
          </div>
        </section>
      </div>
    </div>
  );
}

export default OperationsPrint;
